<template>
	<mw-dialog v-model="dialog" small cancelBtn :title="$t('assessment.delegate')">
		<template v-slot:button>
			<v-tooltip v-if="icon" left>
				<template v-slot:activator="{ on }">
					<v-btn v-on="on" icon @click="dialog = true">
						<v-icon v-if="icon">mdi-account-plus</v-icon>
					</v-btn>
				</template>
				Delegate section to colleague
			</v-tooltip>
			<v-list-item v-else @click="dialog = true">
				<v-list-item-title>
					{{ $t("buttons.delegate") }}
				</v-list-item-title>
			</v-list-item>
		</template>
		<template v-slot:dialog-body>
			<div v-html="$t('collaboration.delegation.before_delegate')"></div>
			<add-colleague v-model="user"></add-colleague>
		</template>
		<template v-slot:dialog-buttons>
			<v-btn @click="delegate" icon color="primary" :disabled="!user">
				<v-icon>mdi-chevron-right-circle</v-icon>
			</v-btn>
		</template>
	</mw-dialog>
</template>

<style lang="less">
.button {
	cursor: pointer;
}
</style>
<script>
import MwDialog from "@c/ui/MwDialog";
import AddColleague from "@c/collaboration/AddColleague";

export default {
	name: "Delegate",
	props: {
		id: { type: String }, // the ID of the response,
		icon: { type: Boolean, default: false },
	},
	data: () => {
		return {
			dialog: false,
			user: null,
		};
	},
	computed: {
		response() {
			return this.$store.state.sectionResponses.data[this.id];
		},
	},
	components: {
		MwDialog,
		AddColleague,
	},
	methods: {
		delegate() {
			var user = this.user;
			this.$store
				.dispatch("emails/delegate", { responseId: this.id, userId: user })
				.then(() => {
					this.$store.dispatch(`sectionResponses/patch`, {
						id: this.id,
						users: { [user]: "delegate" },
						status: "delegated",
					});
					this.$store
						.dispatch(`assessment/patch`, {
							users: { [user]: "delegate" },
						})
						.then(() => {
							this.dialog = false;
						});
				})
				.catch((e) => {
					console.log(e);
				});
		},
	},
};
</script>
